import { USER_LOG_IN_ERROR, USER_LOGGED_IN, USER_LOGGED_OUT, USER_LOGGING_IN, USER_PASSWORD_RESET_COMPLETED, USER_PASSWORD_RESET_REQUESTED } from '../types';
import api from '../middleware/api';
import setAuthorizationHeader from '../utils/setAuthorizationHeader';

export const userLoggedIn = (user) => ({
  type: USER_LOGGED_IN,
  user,
});
export const userLoggedInError = (error) => ({
  type: USER_LOG_IN_ERROR,
  error,
});
const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
});

const userLoggingIn = () => ({
  type: USER_LOGGING_IN,
});

const userPasswordResetRequested = () => ({
  type: USER_PASSWORD_RESET_REQUESTED,
});
const userPasswordResetCompleted = () => ({
  type: USER_PASSWORD_RESET_COMPLETED,
});

const setLoginValues = (user) => {
  if (user.token) {
    localStorage.hcwtvJWT = user.token;
    setAuthorizationHeader(user.token);
  }
};

export const passwordResetRequest = (email) => (dispatch) => api.user.passwordResetRequest(email).then((requested) => dispatch(userPasswordResetRequested(requested)));

export const passwordResetComplete = (resetData) => (dispatch) => api.user.passwordResetComplete(resetData).then((requested) => dispatch(userPasswordResetCompleted(requested)));

export const login = (credentials) => (dispatch) =>
  api.user
    .login(credentials)
    .then((user) => {
      dispatch(userLoggedIn(user));
      setLoginValues(user);
    })
    .catch((data) => {
      dispatch(userLoggedInError(data.response.data.message));
    });
export const logout = () => {
  localStorage.removeItem('hcwtvJWT');
  return (dispatch) => dispatch(userLoggedOut());
};
export const loginByToken = (token) => (dispatch) => {
  dispatch(userLoggingIn());
  api.user
    .loginByToken(token)
    .then((user) => {
      if (user.token) {
        setLoginValues(user);
        dispatch(userLoggedIn(user));
      } else {
        this.logout();
      }
    })
    .catch(() => {
      dispatch(logout());
    });
};
