import React from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from './logo-large.png';
import { logout } from '../../../actions/auth';
import './nav.scss';
import { isLoggedInSelector, userSelector } from '../../../reducers/auth';
import { IconSearch } from '../../svg/SvgIcons';

function Nav() {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => isLoggedInSelector(state));
  const user = useSelector((state) => userSelector(state));
  const dispatch = useDispatch();

  function doSearch(event) {
    if (event.keyCode === 13) {
      navigate(`/search/${event.target.value}`);
    }
  }

  function logoutAll() {
    dispatch(logout());
    navigate('/');
  }

  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <img
            /* eslint-disable-next-line global-require */
            src={logo}
            alt="HCW Media Room"
            className="navbar-logo"
          />
        </Link>
        <button
          className="mr-sm-4 navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="nav nav-fill">
            {!isAuthenticated && (
              <li className="nav-item">
                <NavLink className="navbar-brand nav-link" to="/login" activeclassname="active">
                  Login
                </NavLink>
              </li>
            )}
            {isAuthenticated && (
              <>
                <li className="nav-item">
                  <NavLink className="navbar-brand nav-link" to={isAuthenticated ? '/eventlist' : '/login'} activeclassname="active">
                    Events
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="navbar-brand nav-link" to={isAuthenticated ? '/podcast' : '/login'} activeclassname="active">
                    Podcasts
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="navbar-brand nav-link" to={isAuthenticated ? '/sectors' : '/login'} activeclassbame="active">
                    Sectors
                  </NavLink>
                </li>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <li className="nav-item">
                  <div>
                    <div className="navbar-search text-xs-center mr-1 mr-md-0 ml-1 ml-md-0">
                      <input className="navbar-input mr-sm-1" type="search" aria-label="Search" onKeyUp={doSearch} />
                      <span className="navbar-iconsearch">
                        <IconSearch height={24} color="#CCC" />
                      </span>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <span className="dropdown text-right">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className="ml-1 navbar-brand dropdown-toggle navbar-text-nopadding" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {user.displayName}
                    </a>

                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                      <a role="button" className="navbar-dropdown dropdown-item" onClick={() => logoutAll()}>
                        Log Out
                      </a>
                    </div>
                  </span>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
