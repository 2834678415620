import { createSelector } from 'reselect';
import { COMPANIES_FETCHED, SECTORS_FETCHED, VIDEOS_FETCHED } from '../types';

export default function sectors(state = {}, action = {}) {
  switch (action.type) {
    case SECTORS_FETCHED:
    case VIDEOS_FETCHED:
    case COMPANIES_FETCHED:
      return { ...state, ...action.sectors };
    default:
      return state;
  }
}

// SELECTORS
const sectorsSelector = (state) => state.sectors;

export const allSectorsSelector = createSelector(sectorsSelector, (sectorHash) => Object.values(sectorHash));
export const sectorsByIdSelector = createSelector(sectorsSelector, (sectorHash) => sectorHash);
