import React from 'react';
import './confirmation.css';
import PropTypes from 'prop-types';
import { IconCheckInCircle } from '../../svg/SvgIcons';

function Confirmation(props) {
  const { dismissible, title, description } = props;
  const dismissClass = dismissible ? ' alert-dismissible' : '';
  return (
    <div className={`dismiss alert confirmation-container${dismissClass} fade show`} role="alert">
      <IconCheckInCircle height={20} color="#05635F" />
      <span className="confirmation-title ml-2">{title}</span>
      <div className="confirmation-description">{description}</div>
      {dismissible === true && (
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      )}
    </div>
  );
}

Confirmation.defaultProps = {
  dismissible: false,
};
Confirmation.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dismissible: PropTypes.bool,
};

export default Confirmation;
