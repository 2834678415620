import { createSelector } from 'reselect';
import { COMPANIES_FETCHED, EVENTS_FETCHED, VIDEOS_FETCHED, VIDEOS_SEARCHED } from '../types';

export default function companies(state = {}, action = {}) {
  switch (action.type) {
    case EVENTS_FETCHED:
      return { ...state, ...action.companies };
    case COMPANIES_FETCHED:
      return { ...state, ...action.companies };
    case VIDEOS_FETCHED:
      return { ...state, ...action.companies };
    case VIDEOS_SEARCHED:
      return { ...state, ...action.companies };
    default:
      return state;
  }
}

// SELECTORS
const companiesSelector = (state) => state.companies;

const selectedSectorFilter = (state, selectedSector) => selectedSector;

const selectedCompanyIdFilter = (state, selectedCompanyId) => selectedCompanyId;
export const allCompaniesByIdSelector = createSelector(companiesSelector, (companyHash) => companyHash);
export const allCompaniesHashByIdSelected = createSelector(companiesSelector, (allCompanies) => allCompanies);
export const allCompaniesSelector = createSelector(companiesSelector, (companyHash) => Object.values(companyHash));

export const companyByIdSelector = createSelector([allCompaniesSelector, selectedCompanyIdFilter], (allCompanies, companyId) => allCompanies.find((c) => c.id === companyId));
export const companiesBySectorSelector = createSelector([allCompaniesSelector, selectedSectorFilter], (allCompanies, sector) =>
  allCompanies
    .sort((a, b) => {
      if (String(a.name).toUpperCase() < String(b.name).toUpperCase()) {
        return -1;
      }
      return 1;
    })
    .filter((c) => c.sector === sector),
);
