import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as ReactGA from 'react-ga';
import isEmpty from 'validator/lib/isEmpty';
import isEmail from 'validator/lib/isEmail';
import { passwordResetRequest } from '../../../actions/auth';
import Confirmation from '../../widgets/confirmation/Confirmation';
import * as images from '../../../utils/ImageUtil';
import InlineError from '../../messages/InlineError';
import classNamesConstants from '../../../utils/classnames.constants';

function PasswordResetRequestPage() {
  const confirmation = useRef(null);
  const registerForm = useRef(null);
  const [data, setData] = useState({ email: '' });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });
  const validate = () => {
    const validationErrors = {};
    if (isEmpty(data.email)) {
      validationErrors.email = 'email is a required field';
    } else if (!isEmail(data.email)) {
      validationErrors.email = 'a valid email is required';
    }
    return validationErrors;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validate();
    setErrors({ validationErrors });
    // check if there are any errors
    if (Object.keys(validationErrors).length === 0) {
      dispatch(passwordResetRequest(data))
        .then(() => {
          confirmation.current.className = 'row mt-5';
          registerForm.current.className = 'd-none';
        })
        .catch((err) => {
          setErrors({ errors: err.response });
        });
    }
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className={classNamesConstants.BODY_CONTAINER_CLASS}>
      <div className="row mt-5" ref={registerForm}>
        <div className="col-12 text-center mb-2">
          <div>
            <Link to="/">
              <img src={images.logoImageDark} alt="" className="loginpage-logo p-2 mb-3" />{' '}
            </Link>
          </div>
          <div className="text-left col-12 offset-sm-2 col-sm-8 offset-md-3 col-md-6">
            <h1>Reset Password</h1>
            <h2>Enter your email address to receive a password reset code.</h2>
          </div>
        </div>
        <div className="col-12 offset-sm-2 col-sm-8 offset-md-3 col-md-6 mt-2">
          <div>
            <form onSubmit={onSubmit}>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="email" className="field-label">
                    Email address
                  </label>
                  {errors.email && <InlineError text={errors.email} />}
                  <input name="email" type="text" id="email" aria-describedby="emailHelp" className="form-control input-underline" value={data.email} onChange={onChange} />
                </div>
              </div>
              {errors.data && (
                <div className="col-12">
                  <InlineError text={errors.data.message} />
                </div>
              )}
              <div className="col-12 text-right">
                <button type="submit" className="btn btn-lg btn-primary w-100">
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
          <div className="text-md mt-1 float-right">
            <Link to="/login">Cancel</Link>
          </div>
        </div>
      </div>
      <div className="row mt-5 d-none" ref={confirmation}>
        <div className="offset-2 col-8">
          <Confirmation title="REQUEST SENT" description="An email with instructions has been sent to you. Please follow the instructions to reset your password" />
          <Link className="btn btn-lg btn-primary w-100" to="/login">
            CONTINUE
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetRequestPage;
