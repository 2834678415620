export const getSectorName = (sectorCode) => {
  switch (sectorCode) {
    case 'TECH':
      return 'Technology';
    case 'HLTH':
      return 'Healthcare & Life Sciences';
    case 'METL':
      return 'Metals & Mining';
    case 'CLTC':
      return 'Clean Technology';
    default:
      return '';
  }
};

export const getCompanyDisplayName = (company) => {
  // const symbol = company.exchange && company.exchange.id === 'PRIV' ? '' : `: ${company.tradingSymbol}`;
  let displayName;
  if (company.exchange && company.exchange.id) {
    const symbol = company.exchange.id === 'PRIV' ? '' : company.tradingSymbol;
    displayName = `${company.name} (${company.exchange.description} ${symbol})`;
  } else if (company.tradingSymbol) {
    displayName = `${company.name} (${company.tradingSymbol})`;
  } else {
    displayName = company.name;
  }
  return displayName;
};
