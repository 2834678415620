import React, { useEffect, useState } from 'react';
import './sectorpage.scss';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import * as ReactGA from 'react-ga';
import { isEmpty } from 'lodash';
import logo from './hcw-logo.png';
import { getCompanyDisplayName, getSectorName } from '../../../utils/DataUtil';
import { companiesBySectorSelector } from '../../../reducers/companies';
import { videosBySectorIdSelector } from '../../../reducers/videos';
import { getUrlParameter } from '../../../utils/WebUtil';

function SectorPage() {
  const token = getUrlParameter('token');
  const { sectorCode } = useParams();
  const sectorName = getSectorName(sectorCode);
  const companies = useSelector((state) => companiesBySectorSelector(state, sectorCode));
  const videos = useSelector((state) => videosBySectorIdSelector(state, sectorCode));
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [sectorCode]);

  function getEventVideoLink(video) {
    let base;
    if (video.event) {
      base = `/event/${video.event}/video/${video.id}`;
    } else if (video.company) {
      base = `/company/${video.company}/video/${video.id}`;
    } else {
      base = `/video/${video.id}`;
    }
    if (!isEmpty(token)) {
      base = `${base}/?token=${token}`;
    }
    return base;
  }

  const handleSearchText = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };
  return (
    <>
      <div className="jumbotron jumbotron-fluid banner2">
        <div className="container">
          <div className="col-12 col-sm-9 col-md-7 col-lg-6">
            {/* eslint-disable-next-line global-require */}
            <img src={logo} className="img-fluid" alt="H.C. Wainwright" />
          </div>
          <div className="col-12">
            <p className="lead text-light">Research Companies. View Presentations. Optimize Investments.</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12 text-left text-section">{sectorName}</div>
        </div>
        <div className="row">
          {videos &&
            videos.length !== 0 &&
            videos
              .filter((v) => !v.company)
              .map((v) => (
                <div className="col-12" key={v.id}>
                  <Link to={getEventVideoLink(v)}>{v.description}</Link>
                </div>
              ))}
        </div>
        <div className="row mb-3 mt-3">
          <div className="col-12">
            <h3>Companies</h3>
          </div>
          <div className="col-12 col-sm-6 text-left text-section">
            <input type="text" className="form-control" placeholder="search" onChange={handleSearchText} />
          </div>
        </div>
        <div className="row">
          {companies &&
            companies.length !== 0 &&
            companies
              .filter((c) => c.name.toLowerCase().includes(searchText))
              .map((c) => (
                <div key={c.id} className="col-6 col-md-3">
                  <Link to={`/company/${c.id}`}>
                    <div className="sector-company companypage-othervideo-card p-2">
                      <div className="col-12">
                        <img alt="logo" src={c.logo} className="sectorpage-company-logo" />
                      </div>
                      <div className="col-12 sector-company-text">{getCompanyDisplayName(c)}</div>
                    </div>
                  </Link>
                </div>
              ))}
        </div>
      </div>
    </>
  );
}

SectorPage.propTypes = {};
SectorPage.defaultProps = {};

export default SectorPage;
