import { createSelector } from 'reselect';
import { EVENTS_FETCHED, VIDEOS_FETCHED } from '../types';

export default function companies(state = {}, action = {}) {
  switch (action.type) {
    case EVENTS_FETCHED:
      return { ...state, ...action.events };
    case VIDEOS_FETCHED:
      return { ...state, ...action.events };
    default:
      return state;
  }
}

// SELECTORS
const selectedEventIdFilter = (state, selectedEventId) => selectedEventId;
const selectedEventTypeFilter = (state, selectedEventId) => selectedEventId;

export const eventsMappedById = (state) => state.events;

export const allEventsSelector = createSelector([eventsMappedById], (allEvents) => Object.values(allEvents).sort((a, b) => b.sort - a.sort));
export const eventByEventIdSelector = createSelector([allEventsSelector, selectedEventIdFilter], (allEvents, eventId) => allEvents.find((e) => e.id === parseInt(eventId, 10))); // .sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)))

export const eventByEventTypeSelector = createSelector([allEventsSelector, selectedEventTypeFilter], (allEvents, eventTypeId) => allEvents.filter((e) => e.eventType.id === eventTypeId)); // .sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)))
