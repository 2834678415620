import { schema } from 'normalizr';
import moment from 'moment';

export const sectorSchema = new schema.Entity(
  'sectors',
  {},
  {
    idAttribute: 'id',
  },
);
export const exchangeSchema = new schema.Entity(
  'exchanges',
  {},
  {
    idAttribute: 'id',
  },
);
export const companySchema = new schema.Entity(
  'companies',
  {
    sector: sectorSchema,
  },
  {
    idAttribute: 'id',
  },
);
export const eventSchema = new schema.Entity(
  'events',
  {
    company: companySchema,
  },
  {
    idAttribute: 'id',
    processStrategy: (entity) => ({
      ...entity,
      startDate: entity.startDate ? moment(entity.startDate) : null,
      endDate: entity.endDate ? moment(entity.endDate) : null,
    }),
  },
);
export const videoSchema = new schema.Entity(
  'videos',
  {
    company: companySchema,
    event: eventSchema,
  },
  {
    idAttribute: 'id',
    processStrategy: (entity) => ({
      ...entity,
      dateCreated: moment(entity.dateCreated),
    }),
  },
);
