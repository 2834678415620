import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useMatch } from 'react-router-dom';
import isEmpty from 'validator/lib/isEmpty';
import * as ReactGA from 'react-ga';
import { fetchVideoBySearchText } from '../../../actions/search';
import { searchedVideosSelector } from '../../../reducers/search';
import { allCompaniesByIdSelector } from '../../../reducers/companies';
import './searchpage.css';
import InlineError from '../../messages/InlineError';

function SearchPage() {
  const match = useMatch('/search/:searchText');
  const defaultSearchText = match.params.searchText;
  const dispatch = useDispatch();
  const searchResults = useSelector((state) => searchedVideosSelector(state));
  const companies = useSelector((state) => allCompaniesByIdSelector(state));
  const [searchText, setSearchText] = useState(defaultSearchText);
  const [errors, setErrors] = useState({});
  const inputSearch = useRef(null);
  useEffect(() => {}, [searchText]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const validate = () => {
    const vErrors = {};
    if (isEmpty(searchText) || searchText.length < 4) {
      vErrors.searchText = 'search term must be at least 4 characters';
    }
    return vErrors;
  };

  const doSearch = () => {
    const vErrors = validate();
    setErrors(vErrors);
    // check if there are any errors
    if (Object.keys(vErrors).length === 0) {
      dispatch(fetchVideoBySearchText(searchText)).catch((err) => {
        setErrors({ errors: err.response });
      });
    }
  };
  useEffect(() => {
    if (!isEmpty(defaultSearchText)) {
      doSearch();
    }
  }, [defaultSearchText]);

  const onSubmit = (event) => {
    event.preventDefault();
    doSearch();
  };

  const onChange = (e) => {
    setSearchText(e.target.value);
  };

  const renderSearchResults = () => {
    const output = [];
    const maxStringLength = 150;
    // eslint-disable-next-line array-callback-return
    searchResults.map((v) => {
      const company = companies[v.company];
      output.push(
        <div className="col-12 col-md-6 col-xlg-4">
          <Link to={{ pathname: `/company/${company.id}` }}>
            <div className="card mb-2">
              <img className="card-img-top" src={v.imageThumbnail} alt={v.name} />
              <div className="card-body text-center">
                <h4 className="title">{company.name}</h4>

                {v.name}
                {company && company.description && (
                  <p className="searchpage-text">{company.description.length > maxStringLength ? `${company.description.substring(0, maxStringLength)}...` : company.description}</p>
                )}
              </div>
            </div>
          </Link>
        </div>,
      );
    });
    return output;
  };

  return (
    <div className="searchpage-margin">
      <div className="search-header" />
      <div className="container mt-3 mb-3">
        <h1>Search Results</h1>
        <div className="row">
          <div className="col-12 col-sm-6 ">
            <form onSubmit={onSubmit}>
              <input name="searchText" id="searchText" className="input-underline" type="text" onChange={onChange} defaultValue={defaultSearchText} ref={inputSearch} />
              {errors.searchText && <InlineError text={errors.searchText} />}
            </form>
          </div>
        </div>
        <div className="row mt-3">{renderSearchResults()}</div>
      </div>
    </div>
  );
}

export default SearchPage;
