import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import * as ReactGA from 'react-ga';
import isEmpty from 'validator/es/lib/isEmpty';
import logo from '../sector/hcw-logo.png';
import { videosByEventIdSelector } from '../../../reducers/videos';
import { eventByEventIdSelector } from '../../../reducers/events';
import { getUrlParameter } from '../../../utils/WebUtil';
import { allCompaniesHashByIdSelected } from '../../../reducers/companies';
import { getCompanyDisplayName } from '../../../utils/DataUtil';

function EventVideoListPage(props) {
  const token = getUrlParameter('token');
  const params = useParams();
  const { routeEventId } = props;
  const eventId = routeEventId || parseInt(params.eventId, 10);

  const videos = useSelector((state) => videosByEventIdSelector(state, eventId));
  const event = useSelector((state) => eventByEventIdSelector(state, eventId));
  const companyMappedByCompanyId = useSelector((state) => allCompaniesHashByIdSelected(state));
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (eventId) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [eventId]);

  function getEventVideoLink(videoId) {
    let base = `/event/${eventId}/video/${videoId}`;
    if (!isEmpty(token)) {
      base = `${base}/?token=${token}`;
    }
    return base;
  }

  const handleSearchText = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };
  const hasBanner = event && event.banner;
  const banner = hasBanner ? event.banner : 'banner2';
  return (
    <>
      {event && (
        <>
          <div className={`jumbotron jumbotron-fluid ${banner}`}>
            {!hasBanner && (
              <div className="container">
                <div className="col-12 col-sm-9 col-md-7 col-lg-6">
                  <img src={logo} className="img-fluid" alt="H.C. Wainwright" />
                </div>
                <div className="col-12">
                  <p className="lead text-light">Research Companies. View Presentations. Optimize Investments.</p>
                </div>
              </div>
            )}
          </div>
          <div className="container">
            <div className="row mb-2 mt-3">
              <div className="col-12 text-left text-section">{event && event.name ? <span>{event.name}</span> : <span>Events</span>}</div>
              {event && event.description && <div className="col-12 text-left">{event.description}</div>}
              {event && event.startDate && (
                <div className="col-12 text-left text-md">
                  {event && event.startDate && <span>{event.startDate.format('MMMM Do, YYYY')}</span>}
                  {event && event.endDate && <span> - {event.endDate.format('MMMM Do, YYYY')}</span>}
                </div>
              )}
            </div>
            <div className="row">
              {videos &&
                videos
                  .filter((v) => !v.company)
                  .map((video) => {
                    const col = !event.startDate ? 'col-8 col-md-9 col-lg-10' : 'col-12';
                    return (
                      <>
                        {!event.startDate && <div className="col-4 col-md-3 col-lg-2">{video.dateCreated.format('MMM Do, YYYY')}</div>}
                        <div className={col}>
                          <Link to={getEventVideoLink(video.id)}>{!video.company && <span>{video.description}</span>}</Link>
                        </div>
                      </>
                    );
                  })}
            </div>
            <div className="row">
              {videos.filter((v) => v.company).length > 0 && (
                <div className="col-12 mt-4">
                  <h1>Company Presentations</h1>
                  <div className="text-left text-section mb-3">
                    <input type="text" className="form-control" placeholder="search" onChange={handleSearchText} />
                  </div>
                </div>
              )}
              {videos &&
                videos
                  .filter((v) => v.company)
                  .map((video) => {
                    const company = companyMappedByCompanyId[video.company];
                    if (company && company.name.toLowerCase().includes(searchText)) {
                      return (
                        <div key={company.id} className="col-6 col-md-3">
                          <Link to={`/company/${company.id}`}>
                            <div className="sector-company companypage-othervideo-card p-2">
                              <div className="col-12">
                                <img alt="logo" src={company.logo} className="sectorpage-company-logo" />
                              </div>
                              <div className="col-12 sector-company-text">{getCompanyDisplayName(company)}</div>
                            </div>
                          </Link>
                        </div>
                      );
                    }
                    return null;
                  })}
            </div>
          </div>
        </>
      )}
    </>
  );
}
EventVideoListPage.defaultProps = {
  routeEventId: null,
};
EventVideoListPage.propTypes = {
  routeEventId: PropTypes.number,
};

export default EventVideoListPage;
