import isEmpty from 'validator/lib/isEmpty';
import tvAxios from './tvAxios';

const getTokenBasedUrl = (url, id, token) => {
  let tokenUrl = `${url}/${id}`;
  if (!isEmpty(token)) {
    tokenUrl = `${tokenUrl}?token=${token}`;
  }
  return tokenUrl;
};
const getEventTokenBasedUrl = (url, token) => {
  let tokenUrl = `${url}`;
  if (!isEmpty(token)) {
    tokenUrl = `${tokenUrl}/?token=${token}`;
  }
  return tokenUrl;
};
export default {
  audit: {
    logevent: (logData) =>
      tvAxios
        .post('/audit/log', {
          logActionCode: logData.logAction,
          baseUrl: logData.baseUrl,
          param1: logData.param1,
          param1Value: logData.param1Value,
          param2: logData.param2,
          param2Value: logData.param2Value,
        })
        .then((res) => res.data),
  },
  user: {
    login: (credentials) =>
      tvAxios
        .post('/auth', {
          email: credentials.email,
          password: credentials.password,
        })
        .then((res) => res.data),
    loginByToken: (token) =>
      tvAxios
        .post('/auth/token', {
          token,
        })
        .then((res) => res.data),
    passwordResetRequest: (passwordResetData) => tvAxios.get(`/auth/reset/request/${passwordResetData.email}`, {}).then((res) => res.data),
    passwordResetComplete: (passwordResetData) =>
      tvAxios
        .put(`/auth/reset/complete/${passwordResetData.resetCode}`, {
          password1: passwordResetData.password1,
          password2: passwordResetData.password2,
        })
        .then((res) => res.data),
  },

  guest: {
    register: (registerData) =>
      tvAxios
        .post('/guest/register', {
          name: registerData.name,
          company: registerData.company,
          email: registerData.email,
          phone: registerData.phone,
          aum: registerData.aum,
        })
        .then((res) => res.data),
  },
  videos: {
    fetchAll: () =>
      tvAxios
        .get('/video', {})
        .then((res) => res.data)
        // eslint-disable-next-line no-return-assign
        .catch(() => (window.location = '/login')),
    fetchById: (videoId) =>
      tvAxios
        .get(`/video/${videoId}`, {})
        .then((res) => res.data)
        // eslint-disable-next-line no-return-assign
        .catch(() => (window.location = '/login')),
    fetchByCompanyId: (token, companyId) =>
      tvAxios
        .get(getTokenBasedUrl('/video/company', companyId, token), {})
        .then((res) => res.data)
        // eslint-disable-next-line no-return-assign
        .catch(() => (window.location = '/login')),
    fetchByEventId: (token, eventId) =>
      tvAxios
        .get(getEventTokenBasedUrl(`/event/${eventId}/video`, token), {})
        .then((res) => res.data)
        // eslint-disable-next-line no-return-assign
        .catch(() => (window.location = '/login')),
    fetchBySearchText: (searchText) =>
      tvAxios
        .post('/video/search/', {
          searchTerm: searchText,
        })
        .then((res) => res.data)
        // eslint-disable-next-line no-return-assign
        .catch(() => (window.location = '/login')),
  },
  company: {
    fetchBySector: (sectorCode) => tvAxios.get(`/issuercompany/sector/${sectorCode}`, {}).then((res) => res.data),
    fetchByCompanyId: (token, companyId) => tvAxios.get(getTokenBasedUrl('/issuercompany', companyId, token), {}).then((res) => res.data),
    fetchAll: () => tvAxios.get('/issuercompany', {}).then((res) => res.data),
  },
  feedback: {
    sendFeedback: (feedbackData) =>
      tvAxios
        .post('/feedback', {
          videoId: feedbackData.videoId,
          feedbackType: { id: feedbackData.feedbackType },
        })
        .then((res) => res.data),
  },
  event: {
    fetchAllWithToken: (token) => tvAxios.get(getEventTokenBasedUrl('/guest/event', token), {}).then((res) => res.data),
    fetchAll: () => tvAxios.get('/event', {}).then((res) => res.data),
  },
};
