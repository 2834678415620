import { createSelector } from 'reselect';
import moment from 'moment';
import { VIDEOS_FETCHED, VIDEOS_SEARCHED } from '../types';
import { allCompaniesByIdSelector } from './companies';
import { eventsMappedById } from './events';

export default function videos(state = {}, action = {}) {
  switch (action.type) {
    case VIDEOS_FETCHED:
      return { ...state, ...action.videos };
    case VIDEOS_SEARCHED:
      return { ...state, ...action.videos };
    default:
      return state;
  }
}

// SELECTORS
const videosSelector = (state) => state.videos;
const selectedCompanyIdFilter = (state, selectedCompanyId) => selectedCompanyId;
const selectedEventIdFilter = (state, selectedEventId) => selectedEventId;
const selectedSectorIdFilter = (state, selectedSectorId) => selectedSectorId;

export const allVideosSelector = createSelector(videosSelector, (videosHash) => Object.values(videosHash));
export const allVideosByDateSelector = createSelector(videosSelector, (videosHash) =>
  Object.values(videosHash).sort((left, right) => moment.utc(right.dateCreated.unix()).diff(moment.utc(left.dateCreated.unix()))),
);

export const alLVideosWithCompaniesMappedBySectorSelector = createSelector([allVideosByDateSelector, allCompaniesByIdSelector, eventsMappedById], (allVideos, companyById, allEvents) => {
  const videosMapped = {};
  for (let x = 0; x < allVideos.length; x += 1) {
    const video = allVideos[x];
    if (video.company) {
      const company = companyById[video.company];
      if (company && company.sector) {
        const { sector } = company;
        if (!videosMapped[sector]) {
          videosMapped[sector] = [];
        }
        videosMapped[sector].push({ video, company });
      }
    }
    if (video.event) {
      if (!videosMapped[video.event]) {
        videosMapped[video.event] = [];
      }
      const event = allEvents[video.event];
      videosMapped[video.event].push({ video, event });
    }
  }
  return videosMapped;
});
export const videosByIdSelector = createSelector(videosSelector, (videosHash) => videosHash);

export const videosByCompanyIdSelector = createSelector([allVideosSelector, selectedCompanyIdFilter], (allVideos, companyId) =>
  allVideos.filter((v) => v.company === companyId).sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)),
);
export const videosByEventIdSelector = createSelector([allVideosSelector, selectedEventIdFilter], (allVideos, eventId) =>
  allVideos.filter((v) => v.event === eventId).sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)),
); // .sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)))

export const videosBySectorIdSelector = createSelector([allVideosSelector, selectedSectorIdFilter], (allVideos, sectorId) =>
  allVideos.filter((v) => v.sector && v.sector.id === sectorId).sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)),
); // .sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)))
