import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../../images/hcw-logo.png';
import { allEventsSelector } from '../../../reducers/events';

function EventListPage() {
  const events = useSelector((state) => allEventsSelector(state));
  const displayDate = (theEvent) => {
    if (theEvent.startDate && theEvent.endDate) {
      return `(${theEvent.startDate.format('MMMM Do')} - ${theEvent.endDate.format('Do YYYY')})`;
    }
    if (theEvent.startDate) {
      return `(${theEvent.startDate.format('MMMM Do YYYY')})`;
    }
    return '';
  };
  return (
    <>
      <div className="jumbotron jumbotron-fluid banner2">
        <div className="container">
          <div className="col-12 col-sm-9 col-md-7 col-lg-6">
            {/* eslint-disable-next-line global-require */}
            <img src={logo} className="img-fluid" alt="H.C. Wainwright" />
          </div>
          <div className="col-12">
            <p className="lead text-light">Research Companies. View Presentations. Optimize Investments.</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12 text-center text-section">Events</div>
        </div>
        {events &&
          events
            .sort((e1, e2) => e1.sort - e2.sort)
            .map((event) => (
              <div className="row" key={event.id}>
                <div className="col-12 sector-company-text">
                  <Link to={`/event/${event.id}`}>{event.name}</Link> {displayDate(event)}
                </div>
              </div>
            ))}
      </div>
    </>
  );
}

EventListPage.propTypes = {};

export default EventListPage;
