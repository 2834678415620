import tvAxios from '../middleware/tvAxios';

export default (token = null) => {
  delete tvAxios.defaults.headers.common.authorization;
  if (token) {
    tvAxios.defaults.headers.common.authorization = `Bearer ${token}`;
  }
  tvAxios.defaults.headers.common['Content-Type'] = 'application/json';
  tvAxios.defaults.headers.common.accept = 'application/json';
};
