import { normalize } from 'normalizr';
import { VIDEOS_FETCHED } from '../types';
import api from '../middleware/api';
import { videoSchema } from '../schemas';

const videosFetched = (data) => ({
  type: VIDEOS_FETCHED,
  videos: data.entities.videos,
  companies: data.entities.companies,
  events: data.entities.events,
  sectors: data.entities.sectors,
});

export const fetchVideos = () => (dispatch) => api.videos.fetchAll().then((videos) => dispatch(videosFetched(normalize(videos, [videoSchema]))));

export const fetchVideoById = (videoId) => (dispatch) => api.videos.fetchById(videoId).then((video) => dispatch(videosFetched(normalize(video, videoSchema))));

export const fetchVideosByCompanyId = (token, companyId) => (dispatch) => api.videos.fetchByCompanyId(token, companyId).then((videos) => dispatch(videosFetched(normalize(videos, [videoSchema]))));

export const fetchVideosByEventId = (token, eventId) => (dispatch) => api.videos.fetchByEventId(token, eventId).then((videos) => dispatch(videosFetched(normalize(videos, [videoSchema]))));
