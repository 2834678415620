import { normalize } from 'normalizr';
import api from '../middleware/api';
import { EVENTS_FETCHED } from '../types';
import { eventSchema } from '../schemas';

export const eventsFetched = (data) => ({
  type: EVENTS_FETCHED,
  events: data.entities.events,
  companies: data.entities.companies,
});

export const fetchEventsWithToken = (token) => (dispatch) => {
  api.event.fetchAllWithToken(token).then((events) => dispatch(eventsFetched(normalize(events, [eventSchema]))));
};

export const fetchEvents = () => (dispatch) => {
  api.event.fetchAll().then((events) => dispatch(eventsFetched(normalize(events, [eventSchema]))));
};
