import { createSelector } from 'reselect';
import { USER_LOG_IN_ERROR, USER_LOGGED_IN, USER_LOGGING_IN } from '../types';

export default function auth(state = {}, action = {}) {
  switch (action.type) {
    case USER_LOGGING_IN:
      return { loading: true, error: null };
    case USER_LOGGED_IN:
      return {
        loggedIn: true,
        token: action.user.token,
        bluematrixUrl: action.user.bluematrixUrl,
        error: null,
      };
    case USER_LOG_IN_ERROR:
      return {
        loggedIn: false,
        token: null,
        bluematrixUrl: null,
        error: action.error,
      };
    default:
      return state;
  }
}

// SELECTORS
const allUserSelector = (state) => state.user;
const allAuthSelector = (state) => state.auth;

export const userSelector = createSelector([allUserSelector], (allUser) => allUser);
export const authSelector = createSelector([allAuthSelector], (allAuth) => allAuth);
export const loginErrors = createSelector([allAuthSelector], (allAuth) => (allAuth && allAuth.error ? allAuth.error : null));
export const isLoggedInSelector = createSelector([allAuthSelector], (allAuth) => !!allAuth.loggedIn);
export const isLoadingSelector = createSelector([allAuthSelector], (allAuth) => allAuth && allAuth.loading);
