import { USER_LOGGED_IN, USER_LOGGED_OUT, USER_LOGGING_IN } from '../types';

export default function user(state = {}, action = {}) {
  switch (action.type) {
    case USER_LOGGING_IN:
      return { loading: true };
    case USER_LOGGED_IN:
      return { ...state, ...action.user.user };
    case USER_LOGGED_OUT:
      return {};
    default:
      return state;
  }
}
