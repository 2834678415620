import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { isLoadingSelector, isLoggedInSelector } from './reducers/auth';

// eslint-disable-next-line react/prop-types
export default function PrivateRoute({ children }) {
  const isAuthenticated = useSelector((state) => isLoggedInSelector(state));
  const isLoading = useSelector((state) => isLoadingSelector(state));
  const location = useLocation();
  return (
    // eslint-disable-next-line no-nested-ternary
    isAuthenticated ? (
      children
    ) : !isLoading ? (
      <Navigate
        to={{
          pathname: '/login',
          state: { from: location.pathname },
        }}
      />
    ) : (
      <></>
    )
  );
}
