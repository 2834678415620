import React from 'react';
import PropTypes from 'prop-types';

function InlineError(props) {
  const { text } = props;
  return <div className="field-error">{text}</div>;
}

InlineError.propTypes = {
  text: PropTypes.string.isRequired,
};

export default InlineError;
