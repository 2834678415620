import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import * as ReactGA from 'react-ga';
import { Link, useNavigate } from 'react-router-dom';
import { login } from '../../../actions/auth';
import './loginpage.css';
import { isLoggedInSelector, loginErrors } from '../../../reducers/auth';
import InlineError from '../../messages/InlineError';
import classNamesConstants from '../../../utils/classnames.constants';

const initialData = {
  email: '',
  password: '',
};

function LoginPage(props) {
  const { referrer } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => isLoggedInSelector(state));
  const apiErrors = useSelector((state) => loginErrors(state));
  const [errors, setErrors] = useState({});
  const [data, setData] = useState(initialData);
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  const validate = () => {
    const currentErrors = {};
    if (isEmpty(data.email)) {
      currentErrors.email = 'email is a required field';
    } else if (!isEmail(data.email)) {
      currentErrors.email = 'a valid email is required';
    }
    if (isEmpty(data.password)) {
      errors.password = 'password is a required field';
    }
    return currentErrors;
  };
  const onSubmit = (event) => {
    event.preventDefault();
    setErrors(validate());
    // check if there are any errors
    if (Object.keys(errors).length === 0) {
      dispatch(login(data));
    }
  };
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      navigate(referrer || '/');
    }
  }, [isAuthenticated]);

  return (
    <div className={classNamesConstants.BODY_CONTAINER_CLASS}>
      <div className="row mt-5">
        <div className="col-12 offset-sm-2 col-sm-8 offset-md-3 col-md-6 mt-2">
          <div className="col-12">
            <div className="mb-5">
              <div className="col-12">
                <h1>Login</h1>
                <h2>View and research companies</h2>
              </div>
            </div>
            <form onSubmit={onSubmit}>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="email" className="field-label">
                    Email address
                  </label>
                  {errors.email && <InlineError text={errors.email} />}
                  <input name="email" type="text" id="email" aria-describedby="emailHelp" className="form-control input-underline" value={data.email} onChange={onChange} />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="password" className="field-label">
                    Password
                  </label>
                  {errors.password && <InlineError text={errors.password} />}
                  <input name="password" type="password" id="password" value={data.password} onChange={onChange} className="form-control input-underline" />
                </div>
              </div>
              {apiErrors && (
                <div className="col-12">
                  <InlineError text={apiErrors} />
                </div>
              )}
              <div className="col-12 text-right">
                <button type="submit" className="btn btn-lg btn-primary w-100">
                  LOGIN
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 offset-sm-2 col-sm-8 offset-md-3 col-md-6 mt-2">
          <div className="text-sm mt-3">
            <div>
              Do not have an account? <Link to="/register">Sign Up</Link>
            </div>
            <div>
              Forgot your password? <Link to="/passwordreset/request">Password Reset</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

LoginPage.defaultProps = {
  referrer: null,
};
LoginPage.propTypes = {
  referrer: PropTypes.string,
};
export default LoginPage;
