import React from 'react';
import './PodcastListPage.scss';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { eventByEventTypeSelector } from '../../../reducers/events';
import { logoEvent } from '../../../utils/ImageUtil';

function PodcastListPage() {
  const events = useSelector((state) => eventByEventTypeSelector(state, 'PODC'));
  return (
    <div className="container podcastlist-header">
      <div className="companypage-header row">
        <div className="col-12 mb-3">
          <h1>H.C. Wainwright Media Room Podcasts</h1>
          <h2>Expert topic conversations and industry deep-dives hosted by our Equity Research Team.</h2>
        </div>
      </div>
      {events.map((event) => (
        <div key={event.id} className="row">
          <div className="col-12">
            <h2>
              <Link to={`/event/${event.id}`}>{event.name}</Link>
            </h2>
          </div>
          <div className="col-md-3">
            <Link to={`/event/${event.id}`}>
              <img alt={event.name} className="rounded img-fluid" src={`${logoEvent}/${event.id}/logo.jpg`} />
            </Link>
          </div>
          <div className="col-md-9">{event.description}</div>
        </div>
      ))}
    </div>
  );
}

PodcastListPage.propTypes = {};

export default PodcastListPage;
