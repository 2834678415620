import React, { useEffect, useRef } from 'react';
import './intropage.css';
import * as ReactGA from 'react-ga';
import classNamesConstants from '../../../utils/classnames.constants';

function IntroPage() {
  const vidMain = useRef(null);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const onClickVideo = () => {
    vidMain.current.setAttribute('controls', 'controls');
    vidMain.current.play();
  };

  return (
    <div className={classNamesConstants.BODY_CONTAINER_CLASS}>
      <div className="row mt-5">
        <div className="col-12">
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video controls className="companypage-large" onClick={onClickVideo} ref={vidMain}>
            <source src="https://dzniw0su9j7m4.cloudfront.net/asset/promo-video.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
}

export default IntroPage;
