import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'bootstrap/dist/css/bootstrap.css';
import * as ReactGA from 'react-ga';
import logger from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import App from './App';
import './style/styles.scss';
import rootReducer from './rootReducer';
import { loginByToken } from './actions/auth';

// import 'core-js/es6/map';
// import 'core-js/es6/set';

ReactGA.initialize('UA-120534348-2');

// eslint-disable-next-line no-multi-assign
window.$ = window.jQuery = require('jquery');
window.Popper = require('popper.js');

// require('core-js/es6/set');
// require('core-js/es6/map');
// require('core-js/es6/s');
// require('core-js/fn/array/from');
//  require('core-js/fn/array/find-index');
require('bootstrap/dist/js/bootstrap');

let store;
if (process.env.NODE_ENV === 'development') {
  store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
    devTools: true,
  });
} else {
  store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: false,
  });
}
// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

if (localStorage.hcwtvJWT) {
  const token = { token: localStorage.hcwtvJWT };
  store.dispatch(loginByToken(token.token));
}
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter history={createBrowserHistory()}>
      <App />
    </BrowserRouter>
  </Provider>,
);
// registerServiceWorker();
