import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { allVideosByDateSelector } from '../../../reducers/videos';
import { allCompaniesSelector } from '../../../reducers/companies';
import { allSectorsSelector } from '../../../reducers/sectors';
import { getCompanyDisplayName } from '../../../utils/DataUtil';
import './latestvideopage.scss';

function LatestVideoPage() {
  const videos = useSelector((state) => allVideosByDateSelector(state));
  const companies = useSelector((state) => allCompaniesSelector(state));
  const sectors = useSelector((state) => allSectorsSelector(state));
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const renderLatestVideos = (sector, videosBySector) => (
    <div key={sector.id}>
      <div className="row">
        <div className="col-12">
          <div className="latestvideopage-sector">{sector.description}</div>
        </div>
      </div>
      {videosBySector &&
        companies &&
        videosBySector.map((v) => {
          const company = companies[v.company];
          return (
            <React.Fragment key={v.id}>
              {companies[v.company] && (
                <div className="row">
                  <div className="col-3">
                    <Link to={`/company/${company.id}/${v.id}`}>
                      <span className="latestvideopage-company-name">{`${v.dateCreated.format('MMM Do YYYY')}`}</span>
                    </Link>
                  </div>
                  <div className="col-6">
                    <span className="latestvideopage-company-name">{getCompanyDisplayName(company)}</span>
                    <img className="latestvideopage-company-logo ml-2" src={company.logo} alt="logo" />
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}
    </div>
  );

  return (
    <div>
      <div className="jumbotron jumbotron-fluid">
        <div className="container">
          <h3 className="display-3">Media Room</h3>
          <h4>Latest Presentations</h4>
        </div>
      </div>
      <div className="container">
        {sectors.map((s) =>
          renderLatestVideos(
            s,
            videos.filter((v) => companies[v.company] && companies[v.company].sector === s.id),
          ),
        )}
      </div>
    </div>
  );
}

export default LatestVideoPage;
