import { normalize } from 'normalizr';
import { VIDEOS_SEARCHED } from '../types';
import api from '../middleware/api';
import { videoSchema } from '../schemas';

const videosSearched = (data) => ({
  type: VIDEOS_SEARCHED,
  videos: data.entities.videos,
  companies: data.entities.companies,
});

// eslint-disable-next-line import/prefer-default-export
export const fetchVideoBySearchText = (searchData) => (dispatch) => api.videos.fetchBySearchText(searchData).then((video) => dispatch(videosSearched(normalize(video, [videoSchema]))));
