import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import * as ReactGA from 'react-ga';
import { guestSignupReset, register } from '../../../actions/guests';
import InlineError from '../../messages/InlineError';
import { guestErrors, guestRegistered } from '../../../reducers/guest';
import Confirmation from '../../widgets/confirmation/Confirmation';
import classNamesConstants from '../../../utils/classnames.constants';

const initialData = {
  company: '',
  email: '',
  phone: '',
  aum: '',
};
function RegisterPage() {
  const dispatch = useDispatch();
  const [data, setData] = useState(initialData);
  const confirmation = useRef(null);
  const registerForm = useRef(null);
  const submitButton = useRef(null);
  const [errors, setErrors] = useState({});
  const isRegistered = useSelector((state) => guestRegistered(state));
  const apiErrors = useSelector((state) => guestErrors(state));
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    dispatch(guestSignupReset());
  }, []);
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  function validate() {
    const vErrors = {};
    // check for name
    if (isEmpty(data.name)) {
      vErrors.name = 'name is required';
    }
    // check for investorCompany
    if (isEmpty(data.company)) {
      vErrors.company = 'company is required';
    }
    // check for email
    if (isEmpty(data.email)) {
      vErrors.email = 'email is required';
    } else if (!isEmail(data.email)) {
      vErrors.email = 'a valid email is required';
    }
    // check for phone
    if (isEmpty(data.phone)) {
      vErrors.phone = 'a valid phone is required';
    }
    return vErrors;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    submitButton.current.disabled = true;
    const vErrors = validate();
    setErrors(vErrors);
    if (Object.keys(vErrors).length === 0) {
      dispatch(register(data));
      // .then(() => {
      //   confirmation.current.className = 'row mt-5';
      //   registerForm.current.className = 'd-none';
      // });
      // .catch(err => {
      //       setErrors({ errors: err.response.data.errors });
      //       submitButton.current.disabled = false;
      //   }
      // );
    } else {
      submitButton.current.disabled = false;
    }
  };

  return (
    <div>
      <div className={classNamesConstants.BODY_CONTAINER_CLASS}>
        {!isRegistered && !apiErrors && (
          <div className="row mt-5" ref={registerForm}>
            <div className="col-12 text-center">
              <div className="text-center col-12 offset-sm-2 col-sm-8 offset-md-3 col-md-6">
                <h1>Request Access</h1>
                <h2>Thank you for choosing to join. We will review the information submitted and get back to you shortly.</h2>
              </div>
            </div>
            <div className="col-12 offset-sm-2 col-sm-8 offset-md-3 col-md-6 mt-2">
              <div>
                <form onSubmit={onSubmit}>
                  {errors.data && <span>{errors.data.message}</span>}
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input name="name" type="text" id="name" aria-describedby="yourName" className="form-control input-underline" value={data.name} onChange={onChange} />
                        {errors.name && <InlineError text={errors.name} />}
                      </div>

                      <div className="form-group">
                        <label htmlFor="company">Company</label>
                        <input name="company" type="text" id="company" aria-describedby="companyHelp" className="form-control input-underline" value={data.company} onChange={onChange} />
                        {errors.company && <InlineError text={errors.company} />}
                      </div>
                      <div className="form-group">
                        <label htmlFor="phone">Phone</label>
                        <input name="phone" type="text" id="phone" aria-describedby="companyHelp" className="form-control input-underline" value={data.phone} onChange={onChange} />
                        {errors.phone && <InlineError text={errors.phone} />}
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email address</label>
                        <input name="email" type="text" id="email" aria-describedby="emailHelp" className="form-control input-underline" value={data.email} onChange={onChange} />
                        {errors.email && <InlineError text={errors.email} />}
                      </div>
                      <div className="form-group">
                        <label htmlFor="aum">AUM</label>
                        <input name="aum" type="text" id="aum" aria-describedby="emailHelp" className="form-control input-underline" value={data.aum} onChange={onChange} />
                        {errors.aum && <InlineError text={errors.aum} />}
                      </div>
                      <button type="submit" className="btn btn-lg btn-primary w-100" ref={submitButton}>
                        Request Access
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="text-md mt-1">
                Already have an account? <Link to="/login">Sign In</Link>
              </div>
            </div>
          </div>
        )}
        {isRegistered && (
          <div className="row mt-5" ref={confirmation}>
            <div className="offset-2 col-8">
              <Confirmation title="REQUEST SENT" description="Thank you for requesting to join HCW Media Room, we will contact you to shortly" />
            </div>
          </div>
        )}
        {apiErrors && (
          <div className="offset-2 col-8 mt-5">
            <h2>There was a problem registering you. If the problem persists, please contact us for additional help.</h2>
          </div>
        )}
      </div>
    </div>
  );
}

export default RegisterPage;
