import { createSelector } from 'reselect';
import { GUEST_REGISTER_RESET, GUEST_REGISTERED, GUEST_REGISTERED_ERROR } from '../types';

export default function guest(state = {}, action = {}) {
  switch (action.type) {
    case GUEST_REGISTERED:
      return { registered: true };
    case GUEST_REGISTERED_ERROR:
      return {
        error: action.error,
      };
    case GUEST_REGISTER_RESET:
      return {};
    default:
      return state;
  }
}

const allGuestSelector = (state) => state.guest;

export const guestErrors = createSelector([allGuestSelector], (allGuest) => (allGuest && allGuest.error ? allGuest.error : null));

export const guestRegistered = createSelector([allGuestSelector], (allGuest) => (allGuest ? allGuest.registered : false));
