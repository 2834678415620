import React from 'react';
import './videodisclaimer.scss';

function VideoDisclaimer() {
  return (
    <div className="videodisclaimer-text">
      The information contained in the video presentations provided on this website (“Presentations”) represent the views and opinions of the original creators of the Presentation and does not
      represent the views or opinions of H.C. Wainwright & Co., LLC. (“HCW”) The mere appearance of the Presentation on the Site does not constitute an endorsement by HCW or its affiliates of the
      Presentation. The Presentations are provided solely for informational purposes and are not to be construed as a solicitation or offer to buy or sell any property or financial product or
      instrument. HCW does not make any representation or warranties with respect to the accuracy, applicability or completeness of the Presentation. Nothing in the Presentation is intended to
      constitute investment, legal, tax, accounting or other professional advice. You should not rely on the information provided or made accessible through the use of this website for making
      financial or investment decisions. Consult with an appropriate professional for specific advice tailored to your situation and/or to verify the accuracy of the information provided herein prior
      to making any investment decisions. Please see our
      <a href="http://hcwco.com/terms-of-use">Terms of Use</a> for more information
    </div>
  );
}

VideoDisclaimer.propTypes = {};
VideoDisclaimer.defaultProps = {};

export default VideoDisclaimer;
