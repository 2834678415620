import React from 'react';
import PropTypes from 'prop-types';

function IconSearch({ height, color }) {
  return (
    <svg fill={color} height={height} viewBox="0 0 24 24" width={height} xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
}

IconSearch.propTypes = {
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

function IconThumbsUp({ id, height, color }) {
  return (
    <svg fill={color} height={height} viewBox="0 0 24 24" width={height} xmlns="http://www.w3.org/2000/svg">
      <path
        id={id}
        d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L23 10z"
      />
    </svg>
  );
}

IconThumbsUp.propTypes = {
  id: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

function IconCheckInCircle({ height, color }) {
  return (
    <svg fill={color} width={height} height={height} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ic_check_in_circle_black_24px" transform="translate(-2.000000, -2.000000)">
          <path
            d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.58,20 4,16.42 4,12 C4,7.58 7.58,4 12,4 C16.42,4 20,7.58 20,12 C20,16.42 16.42,20 12,20 Z"
            id="Shape"
            fill={color}
            fillRule="nonzero"
          />
          <polygon id="Shape" fill={color} fillRule="nonzero" points="10.59 13.58 8.42 11.41 7 12.82 10.59 16.41 17.59 9.41 16.18 8" />
          <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
        </g>
      </g>
    </svg>
  );
}
IconCheckInCircle.propTypes = {
  height: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export { IconSearch, IconThumbsUp, IconCheckInCircle };
