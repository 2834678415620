import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useMatch } from 'react-router-dom';
import * as ReactGA from 'react-ga';
import isEmpty from 'validator/lib/isEmpty';
import { passwordResetComplete } from '../../../actions/auth';
import Confirmation from '../../widgets/confirmation/Confirmation';
import InlineError from '../../messages/InlineError';
import classNamesConstants from '../../../utils/classnames.constants';

function PasswordResetCompletePage() {
  const confirmation = useRef(null);
  const registerForm = useRef(null);
  const match = useMatch('/passwordreset/complete/:resetCode');
  const { resetCode } = match.params;
  const dispatch = useDispatch();
  const [data, setData] = useState({ email: '' });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setData({ ...data, resetCode });
  }, []);

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });
  const validate = () => {
    const vErrors = {};
    if (!data.password1 || isEmpty(data.password1)) {
      vErrors.password1 = 'new password is required';
    } else if (!data.password2 || isEmpty(data.password2)) {
      vErrors.password2 = 'a confirmation password is required';
    } else if (data.password1 !== data.password2) {
      vErrors.password2 = 'passwords do not match';
    } else if (!data.resetCode || isEmpty(data.resetCode)) {
      vErrors.resetCode = 'reset code is required';
    }
    return vErrors;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const clientErrors = validate();
    setErrors(clientErrors);
    // check if there are any errors
    if (Object.keys(clientErrors).length === 0) {
      dispatch(passwordResetComplete(data))
        .then(() => {
          confirmation.current.className = 'row mt-5';
          registerForm.current.className = 'd-none';
        })
        .catch((err) => {
          setErrors({ server: err.response.data.message });
        });
    }
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className={classNamesConstants.BODY_CONTAINER_CLASS}>
      <div className="row mt-5" ref={registerForm}>
        <div className="col-12 text-center mb-2">
          <div className="text-left col-12 offset-sm-2 col-sm-8 offset-md-3 col-md-6">
            <h1>Reset Password Confirm</h1>
            <h2>Enter your new password below to continue</h2>
          </div>
        </div>
        <div className="col-12 offset-sm-2 col-sm-8 offset-md-3 col-md-6 mt-2">
          <div>
            <form onSubmit={onSubmit}>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="password1" className="field-label">
                    New Password
                  </label>
                  {errors.password1 && <InlineError text={errors.password1} />}
                  <input name="password1" type="password" id="password1" placeholder="new password" className="form-control" onChange={onChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="password2" className="field-label">
                    New Password
                  </label>
                  {errors.password2 && <InlineError text={errors.password2} />}
                  <input name="password2" type="password" id="password2" placeholder="new password" className="form-control" onChange={onChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="resetCode" className="field-label">
                    Reset Code
                  </label>
                  {errors.resetCode && <InlineError text={errors.resetCode} />}
                  <input name="resetCode" type="text" id="resetCode" defaultValue={resetCode} placeholder="reset code" className="form-control" onChange={onChange} />
                </div>
              </div>
              {errors.server && (
                <div className="col-12">
                  <InlineError text={errors.server} />
                </div>
              )}
              <div className="col-12 text-right">
                <button type="submit" className="btn btn-lg btn-primary w-100">
                  SUBMIT
                </button>
              </div>
            </form>
          </div>

          <div className="text-md mt-1 float-right">
            <Link to="/login">Login</Link>
          </div>
        </div>
      </div>
      <div className="row mt-5 d-none" ref={confirmation}>
        <div className="offset-2 col-8">
          <Confirmation title="PASSWORD UPDATED" description="Your password has been updated." />
          <Link className="btn btn-lg btn-primary w-100" to="/login">
            CONTINUE
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PasswordResetCompletePage;
