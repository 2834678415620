import { createSelector } from 'reselect';
import { VIDEOS_SEARCHED } from '../types';

export default function search(state = {}, action = {}) {
  switch (action.type) {
    case VIDEOS_SEARCHED:
      return action.videos ? action.videos : [];
    default:
      return state;
  }
}

// SELECTORS
const searchSelector = (state) => state.search;

export const searchedVideosSelector = createSelector(searchSelector, (videosHash) => Object.values(videosHash));
