import React, { useEffect } from 'react';
import './sectorlistpage.scss';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import logo from '../sector/hcw-logo.png';
import { isLoggedInSelector } from '../../../reducers/auth';
import { fetchEventsWithToken } from '../../../actions/events';
import { allEventsSelector } from '../../../reducers/events';
import { getUrlParameter } from '../../../utils/WebUtil';

function SectorListPage(props) {
  const { showEvents } = props;
  const token = getUrlParameter('token');
  const isAuthenticated = useSelector((state) => isLoggedInSelector(state));
  const dispatch = useDispatch();
  const events = useSelector((state) => allEventsSelector(state));
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {
    dispatch(fetchEventsWithToken(token));
  }, []);

  const renderSectorCard = (code, description, dateText = null, className = null) => {
    const colClasses = className || 'col-sm-6 col-md-4 col-lg-2 pr-md-1 col-centered';
    return (
      <div className={`col-12 ${colClasses}`} key={code}>
        <Link to={isAuthenticated ? `/sector/${code}` : '/login'}>
          <div className="card-sector">
            <div className="card-body card-body-sector">
              <div className="mt-5">{description}</div>
              {dateText && <div className="mt-1">{dateText}</div>}
            </div>
          </div>
        </Link>
      </div>
    );
  };

  const displayDate = (theEvent) => {
    if (theEvent.startDate && theEvent.endDate) {
      return `${theEvent.startDate.format('MMMM Do')} - ${theEvent.endDate.format('Do YYYY')}`;
    }
    if (theEvent.startDate) {
      return theEvent.startDate.format('MMMM Do YYYY');
    }
    return '';
  };
  return (
    <>
      <div className="jumbotron jumbotron-fluid banner2">
        <div className="container">
          <div className="col-12 col-sm-9 col-md-7 col-lg-6">
            <img src={logo} className="img-fluid" alt="H.C. Wainwright" />
          </div>
          <div className="col-12">
            <p className="lead text-light">Research Companies. View Presentations. Optimize Investments.</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12 text-center text-section">Sectors</div>
        </div>
        <div className="row">
          {renderSectorCard('HLTH', 'Healthcare & Life Sciences')}
          {renderSectorCard('METL', 'Metals & Mining')}
          {renderSectorCard('CLTC', 'Cleantech')}
          {renderSectorCard('SHIP', 'Shipping & Maritime')}
          {renderSectorCard('TECH', 'Technology')}
        </div>
        {showEvents && events && events.length > 0 && (
          <div className="row">
            <div className="col-12 text-center text-section mb-3">Events</div>
            {events.map((event) => renderSectorCard(event.id, event.name, displayDate(event), 'col-sm-6'))}
          </div>
        )}
      </div>
    </>
  );
}

SectorListPage.defaultProps = {
  showEvents: true,
};
SectorListPage.propTypes = {
  showEvents: PropTypes.bool,
};
export default SectorListPage;
