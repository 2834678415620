export const AUDITEVENT_LOGIN = 'LOGN';
export const AUDITEVENT_PAGE = 'PAGE';
export const AUDITEVENT_PLAY = 'PLAY';
export const AUDITEVENT_REGISTER = 'RSTR';

export const createLog = (logactionCode, baseUrl, param1, param1Value, param2, param2Value) => ({
  logAction: logactionCode,
  baseUrl,
  param1,
  param1Value,
  param2,
  param2Value,
});
