import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RegisterPage from './components/pages/register/RegisterPage';
import SearchPage from './components/pages/search/SearchPage';
import PasswordResetRequestPage from './components/pages/passwordreset/PasswordResetRequestPage';
import PasswordResetCompletePage from './components/pages/passwordreset/PasswordResetCompletePage';
import SectorPage from './components/pages/sector/SectorPage';
import VideoPlayerPage from './components/pages/videoplayer/VideoPlayerPage';
import IntroPage from './components/pages/intro/IntroPage';
import LatestVideoPage from './components/pages/latestvideo/LatestVideoPage';
import LoginPage from './components/pages/login/LoginPage';
import SectorListPage from './components/pages/sectorlist/SectorListPage';
import Nav from './components/widgets/nav/Nav';
import PrivateRoute from './PrivateRoute';
import EventVideoListPage from './components/pages/eventvideolist/EventVideoListPage';
import Footer from './components/widgets/footer/Footer';
import HomePage from './components/pages/home';
import { isLoadingSelector, isLoggedInSelector } from './reducers/auth';
import EventListPage from './components/pages/eventlist';
import { fetchEvents } from './actions/events';
import { fetchVideos } from './actions/videos';
import PodcastListPage from './components/pages/podcastlist';

// need to pass in location due to a router issue
function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [referrer, setReferrer] = useState(null);
  const isAuthenticated = useSelector((state) => isLoggedInSelector(state));
  const isLoading = useSelector((state) => isLoadingSelector(state));
  useEffect(() => {
    // this value would have been set in PrivateRoute, as a state prop
    if (location && location.state && location.state.from) {
      setReferrer(location.state.from.pathname);
    }
  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchEvents());
      dispatch(fetchVideos());
    }
  }, [isAuthenticated]);

  return (
    <>
      {!isLoading && (
        <>
          <Nav />
          <main className="flex-fill">
            <Routes>
              {isAuthenticated && (
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <HomePage />
                    </PrivateRoute>
                  }
                />
              )}
              {!isAuthenticated && <Route path="/" element={<SectorListPage />} />}
              <Route path="/sectors" element={<SectorListPage showEvents={false} />} />
              <Route path="/login" element={<LoginPage referrer={referrer || '/'} />} />
              <Route path="/intro" element={<IntroPage />} />
              <Route path="/passwordreset/request" element={<PasswordResetRequestPage />} />
              <Route path="/passwordreset/complete/:resetCode" element={<PasswordResetCompletePage />} />
              <Route path="/register" element={<RegisterPage />} />
              {/* Events */}
              <Route path="/eventlist" element={<EventListPage />} />

              <Route path="/event/:eventId" element={<EventVideoListPage />} />
              <Route path="/event/:eventId/video/:videoId" element={<VideoPlayerPage />} />

              {/* routes the permanent issuer routes */}
              <Route path="/issuer/:companyId" element={<VideoPlayerPage />} />
              <Route path="/issuer/:companyId/video/:videoId" element={<VideoPlayerPage />} />
              {/* {isAuthenticated && <> */}
              {/* Not ideal that we are hard coding this here, but since we just have this one that needs this going to do this for now.  if we have more like this, we will need to make this configurable from and grab these from the database */}
              <Route
                path="/podcast/"
                element={
                  <PrivateRoute>
                    <PodcastListPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/psychedelicsweekly"
                element={
                  <PrivateRoute>
                    <EventVideoListPage routeEventId={6} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/video/latest"
                element={
                  <PrivateRoute>
                    <LatestVideoPage />
                  </PrivateRoute>
                }
              />
              {/* <UserRoute path="/company/:companyId" exact component={VideoPlayerPage} location={location}/> */}
              <Route
                path="/company/:companyId"
                element={
                  <PrivateRoute>
                    <VideoPlayerPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/company/:companyId/video/:videoId"
                element={
                  <PrivateRoute>
                    <VideoPlayerPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/sector/:sectorCode"
                element={
                  <PrivateRoute>
                    <SectorPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/search/:searchText"
                element={
                  <PrivateRoute>
                    <SearchPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/search/:searchText"
                element={
                  <PrivateRoute>
                    <SearchPage />
                  </PrivateRoute>
                }
              />
              {/* </> */}
              {/* } */}
              {/* {<Route path="/"> */}
              {/*  <Redirect to='/login'/> */}
              {/* </Route>} */}
            </Routes>
          </main>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
