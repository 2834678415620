import { normalize } from 'normalizr';
import { COMPANIES_FETCHED } from '../types';
import api from '../middleware/api';
import { companySchema } from '../schemas';

export const companiesFetched = (data) => ({
  type: COMPANIES_FETCHED,
  companies: data.entities.companies,
  sectors: data.entities.sectors,
});
export const fetchAllCompany = () => (dispatch) => api.company.fetchAll().then((company) => dispatch(companiesFetched(normalize(company, [companySchema]))));

export const fetchCompanyById = (token, companyId) => (dispatch) => api.company.fetchByCompanyId(token, companyId).then((company) => dispatch(companiesFetched(normalize(company, [companySchema]))));

export const fetchCompaniesBySector = (sectorCode) => (dispatch) => api.company.fetchBySector(sectorCode).then((companies) => dispatch(companiesFetched(normalize(companies, [companySchema]))));
