import React from 'react';
import './footer.scss';

function Footer() {
  return (
    <footer className="footer mt-auto py-3">
      <div className="container">
        <div className="row mt-3">
          <div className="col-5 col-md-5 col-lg-7 mb-md-3">
            <div className="footer-text">© Copyright 2018 – H.C. Wainwright & Co.</div>
          </div>
          <div className="col-7 col-md-7 col-lg-5 mb-md-3">
            <div className="footer-text">
              <a href="http://hcwco.com/about-us/privacy-policy">Privacy Policy</a> | <a href="http://hcwco.com/about-us/business-continuity-plan">Business Continuity Plan</a> |{' '}
              <a href="http://hcwco.com/about-us/electronic-communications-disclaimer">Electronic Communications</a>&nbsp;|&nbsp;<a href="http://hcwco.com/about-us/contact">Contact Us</a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-5 col-md-5 col-lg-7 ">
            <div className="footer-text">430 Park Avenue, New York, New York 10022</div>
          </div>
          <div className="col-7 col-md-7 col-lg-5">
            <div className="footer-text">
              Member <a href="http://www.finra.org/">FINRA</a>&nbsp;|&nbsp;<a href="http://www.sipc.org/">SIPC</a>&nbsp;|&nbsp;
              <a href="https://private.tagaudit.com/do/display?page=Rules&rule=6&mpid=wain">Rule 606 Reports</a>&nbsp;|&nbsp;
              <a href="http://brokercheck.finra.org/">BrokerCheck</a>&nbsp;|&nbsp;
              <a href="http://hcwco.com/terms-of-use">Terms of Use</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {};
Footer.defaultProps = {};

export default Footer;
