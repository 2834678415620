import { GUEST_REGISTER_RESET, GUEST_REGISTERED, GUEST_REGISTERED_ERROR } from '../types';
import api from '../middleware/api';

const guestSignedUp = (guest) => ({
  type: GUEST_REGISTERED,
  guest,
});
export const guestSignupError = (error) => ({
  type: GUEST_REGISTERED_ERROR,
  error,
});
export const guestSignupReset = () => ({
  type: GUEST_REGISTER_RESET,
});
export const register = (registerData) => (dispatch) =>
  api.guest
    .register(registerData)
    .then((guest) => {
      dispatch(guestSignedUp(guest));
    })
    .catch((data) => dispatch(guestSignupError(data.response.data.message)));
