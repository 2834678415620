import { combineReducers } from 'redux';

import user from './reducers/user';
import guest from './reducers/guest';
import videos from './reducers/videos';
import companies from './reducers/companies';
import sectors from './reducers/sectors';
import feedback from './reducers/feedback';
import search from './reducers/search';
import auth from './reducers/auth';
import events from './reducers/events';
import { USER_LOGGED_OUT } from './types';

const appReducer = combineReducers({
  user,
  auth,
  events,
  guest,
  sectors,
  companies,
  videos,
  search,
  feedback,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGGED_OUT) {
    // eslint-disable-next-line no-param-reassign,no-undefined
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
