export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOG_IN_ERROR = 'USER_LOG_IN_ERROR';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_LOGGING_IN = 'USER_LOGGING_IN';

export const USER_PASSWORD_RESET_REQUESTED = 'USER_PASSWORD_RESET_REQUESTED';
export const USER_PASSWORD_RESET_COMPLETED = 'USER_PASSWORD_RESET_COMPLETED';

export const GUEST_REGISTERED = 'GUEST_REGISTERED';
export const GUEST_REGISTERED_ERROR = 'GUEST_REGISTER_ERROR';
export const GUEST_REGISTER_RESET = 'GUEST_REGISTER_RESET';

export const VIDEOS_FETCHED = 'VIDEOS_FETCHED';
export const VIDEOS_SEARCHED = 'VIDEOS_SEARCHED';

export const EVENTS_FETCHED = 'EVENTS_FETCHED';
export const COMPANIES_FETCHED = 'COMPANIES_FETCHED';
export const SECTORS_FETCHED = 'SECTOR_FETCHED';

export const FEEDBACK_SENT = 'FEEDBACK_SENT';
